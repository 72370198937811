<template>
  <v-dialog fullscreen :value="true">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark :to="{ name: 'user-list', query: $route.query }" exact>
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <pre v-text="JSON.stringify(user, null, 2)" />
      </v-card-text>
      <v-divider />
      <v-subheader>顧客情報</v-subheader>
      <v-card-text>
        <router-link
          v-for="c in customers"
          :key="c.id"
          :to="{ name: 'customer-detail', params: { id: c.uuid } }"
        >
          {{ c.shopName }} {{ c.account }} {{ c.familyNameKana }}
          {{ c.firstNameKana }} {{ c.familyName }} {{ c.firstName }}
          <br />
        </router-link>
      </v-card-text>
      <v-divider />
      <v-subheader>予約履歴</v-subheader>
      <v-card-text>
        <pre v-text="JSON.stringify(reservations, null, 2)" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserDetailView',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    user: null,
    reservations: [],
  }),
  computed: {
    customers() {
      let user = this.user
      if (!user) return null
      return user.customers
    },
  },
  created() {
    this.fetchData()
    this.fetchReservation()
  },
  methods: {
    async fetchData() {
      this.user = await this.$api.users(this.id).get()
    },

    async fetchReservation() {
      let { token } = await this.$api.users(this.id).obtainToken()
      let config = this.$api.http.defaults
      let { results } = await this.$api.axios
        .get('my/reservations/', {
          baseURL: config.baseURL,
          headers: {
            common: { Authorization: 'JWT ' + token },
          },
        })
        .then(res => res.data)
      this.reservations = results
    },
  },
}
</script>

<style></style>
