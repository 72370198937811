<script>
export default {
  name: 'FirebaseUserView',
  data: () => ({
    uid: '',
    isLoading: false,
  }),
  methods: {
    async search() {
      try {
        this.isLoading = true
        const user = await this.$api.firebaseUsers(this.uid).get()
        this.$router.push({ path: `/firebase_user/${user.uid}` })
      } catch (err) {
        console.error(err)
        this.$toast.error('ユーザーが見つかりませんでした')
      }
      this.isLoading = false
    },
  },
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Firerbaseユーザー検索</h1>
        <v-text-field v-model="uid" label="Firebase uid" outlined />
        <v-btn :loading="isLoading" @click="search">検索</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
