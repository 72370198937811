<script>
export default {
  name: 'FirebaseUserDetailView',
  data: () => ({
    user: {},
    isLoading: false,
    form: {
      email: '',
      phoneNumber: '',
    },
    errors: {},
  }),
  computed: {
    customer() {
      return this.user.customer || {}
    },
  },
  async created() {
    try {
      this.isLoading = true
      this.user = await this.$api.firebaseUsers(this.$route.params.uid).get()
      this.form.email = this.user.email
      this.form.phoneNumber = this.user.phoneNumber
    } catch (err) {
      console.error(err)
    }
    this.isLoading = false
  },
  methods: {
    async update() {
      try {
        this.isLoading = true
        await this.$api.firebaseUsers(this.$route.params.uid).update(this.form)
        this.$toast.success('更新しました')
      } catch (err) {
        console.error(err)
        this.errors = err.response.data.errors
        this.$toast.error('更新に失敗しました')
      }
      this.isLoading = false
    },
  },
}
</script>

<template>
  <v-container>
    <v-text-field v-model="form.email" label="Firebase Email" outlined />
    <v-text-field
      v-model="form.phoneNumber"
      label="Firebase Phone Number"
      outlined
    />
    <v-btn color="primary" :loading="isLoading" @click="update">更新</v-btn>

    <v-simple-table>
      <tbody>
        <tr>
          <th>UID</th>
          <td v-text="user.uid" />
        </tr>
        <tr>
          <th>Email</th>
          <td v-text="user.email" />
        </tr>
        <tr>
          <th>Phone Number</th>
          <td v-text="user.phoneNumber" />
        </tr>
        <tr>
          <th>Family Name</th>
          <td v-text="user.familyName" />
        </tr>
        <tr>
          <th>Given Name</th>
          <td v-text="user.firstName" />
        </tr>
        <tr>
          <th>Family Name Kana</th>
          <td v-text="user.familyNameKana" />
        </tr>
        <tr>
          <th>Given Name Kana</th>
          <td v-text="user.firstNameKana" />
        </tr>
      </tbody>
    </v-simple-table>

    <h2>Customer</h2>
    <v-simple-table>
      <tbody>
        <tr>
          <th>ID</th>
          <td v-text="customer.uuid" />
        </tr>
        <tr>
          <th>No.</th>
          <td v-text="customer.account" />
        </tr>
        <tr>
          <th>Shop</th>
          <td v-text="customer.shopName" />
        </tr>
        <tr>
          <th>Family Name</th>
          <td v-text="user.familyName" />
        </tr>
        <tr>
          <th>Given Name</th>
          <td v-text="user.firstName" />
        </tr>
        <tr>
          <th>Family Name Kana</th>
          <td v-text="user.familyNameKana" />
        </tr>
        <tr>
          <th>Given Name Kana</th>
          <td v-text="user.firstNameKana" />
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>
