type QueryDict = Record<string, string | (string | null)[] | unknown>
export type FilterDefine = {
  type: any
  default: any
}

function parseNumber(x: any, defaultValue: number) {
  let n = parseInt(x)
  return isNaN(n) ? defaultValue : n
}

function parseBoolean(key: string, queryDict: QueryDict, field: FilterDefine) {
  if (!(key in queryDict)) return field.default
  let x: any = queryDict[key]
  if (x === null) return null
  if (x === undefined) return null
  if (x === '') return null
  if (x === 'true') return true
  if (x === 'false') return false
  return Boolean(x)
}
function enforceArray(x: any) {
  if (!x) return []
  else if (!Array.isArray(x)) return [x]
  return x
}
function parseString(x: any, defaultValue: string | null) {
  if (typeof x === 'string') return x
  return defaultValue
}

export default class FilterUtil {
  _fields!: Record<string, FilterDefine>

  constructor(filterDef: Record<string, FilterDefine>) {
    this._fields = filterDef
  }

  get keys() {
    return Object.keys(this._fields)
  }

  parseQueryDict(queryDict: QueryDict) {
    let parsed: Record<string, any> = {}
    Object.keys(this._fields).forEach(k => {
      let field = this._fields[k]
      let defaultValue = field.default === undefined ? null : field.default
      if (field.type === Number) {
        parsed[k] = parseNumber(queryDict[k], defaultValue)
      } else if (field.type === Array) {
        parsed[k] = enforceArray(queryDict[k])
      } else if (field.type === Boolean) {
        parsed[k] = parseBoolean(k, queryDict, field)
      } else if (field.type === String) {
        parsed[k] = parseString(queryDict[k], defaultValue)
      } else {
        parsed[k] = defaultValue
      }
    })
    return parsed
  }

  mergeQueryDict(old: Record<string, any>, value: Record<string, any>) {
    return this.cleanQueryDict(Object.assign({}, old, value))
  }

  cleanQueryDict(queryDict: Record<string, any>) {
    let r: Record<string, any> = {}
    Object.keys(queryDict).forEach(k => {
      let v = queryDict[k]
      if (this.isRemovalKey(k, v)) return
      if (Array.isArray(v)) r[k] = v.filter(x => x)
      else r[k] = v
    })
    return r
  }

  isRemovalKey(k: string, v: any) {
    let field = this._fields[k]
    if (!field) return false
    if (field.default === v) return true
    if (Array.isArray(v) && v.length === 0) return true
    return false
  }
}
