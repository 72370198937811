<template>
  <v-layout column fill-height>
    <h2>アプリユーザ管理</h2>
    <v-layout wrap>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="filter.search"
          label="検索"
          placeholder="ユーザ名, 氏名"
          hide-details
          append-icon="search"
          clearable
          @click:clear="clearSearch"
          @click:append="updateQuery"
          @keypress.enter="updateQuery"
        />
      </v-flex>
      <v-flex xs3>
        <v-switch
          v-model="filter.hasCustomer"
          :true-value="true"
          :false-value="null"
          label="顧客登録済"
          hide-details
          @change="updateQuery"
        />
      </v-flex>
    </v-layout>
    <virtual-data-table
      :items="users"
      :identifier="identifier"
      :total-count="totalCount"
      :ordering.sync="filter.ordering"
      @update:ordering="updateQuery"
      @infinite="fetchData"
    >
      <virtual-data-table-column
        label="uid"
        prop="uid"
        width="200"
        order-field="uid"
        fixed="left"
      >
        <template #default="props">
          <a @click="pushDetail(props)">{{ props.item.uid }}</a>
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column label="username" prop="username" />
      <virtual-data-table-column label="email" prop="email" />

      <virtual-data-table-column
        label="セイ"
        prop="familyNameKana"
        width="100"
      />
      <virtual-data-table-column
        label="メイ"
        prop="firstNameKana"
        width="100"
      />
      <virtual-data-table-column label="氏名" width="100">
        <template #default="props">
          {{ props.item.familyName }}
          {{ props.item.firstName }}
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column
        label="電話番号"
        prop="phoneNumber"
        width="110"
      />
      <virtual-data-table-column label="顧客情報" prop="customer">
        <template #default="{ item: { customer } }">
          <template v-if="customer">
            {{
              `${customer.familyNameKana} ${customer.firstNameKana} (${customer.account})`
            }}
          </template>
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column
        label="ユーザ登録日時"
        prop="dateJoined"
        width="150"
        order-field="date_joined"
      >
        <template #default="props">
          {{ props.item.dateJoined | datetime }}
        </template>
      </virtual-data-table-column>
    </virtual-data-table>
    <router-view />
  </v-layout>
</template>

<script>
import FilterUtil from '@/filter-util'
const filterUtil = new FilterUtil({
  search: { type: String, default: null },
  ordering: { type: String, default: '' },
  hasCustomer: { type: Boolean, default: null },
})

export default {
  name: 'UserListView',
  components: {},
  data: () => ({
    filter: {
      search: null,
      ordering: '',
      hasCustomer: null,
    },
    query: null,
    users: [],
    next: null,
    totalCount: 0,
  }),
  computed: {
    identifier() {
      return JSON.stringify(this.query)
    },
  },
  watch: {
    '$route.query'(query) {
      console.log('query', query)
      this.query = filterUtil.parseQueryDict(query)
    },
    identifier() {
      this.users = []
      this.next = null
      this.getTotalCount()
    },
  },
  created() {
    this.query = filterUtil.parseQueryDict(this.$route.query)
    Object.assign(this.filter, this.query)
    this.getTotalCount()
  },
  methods: {
    async getTotalCount() {
      this.totalCount = await this.$api.users().count(this.query)
    },
    async fetchData($state) {
      try {
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.users().list(this.query))
        this.users = this.users.concat(results)
        this.next = next
        if (this.users.length) $state.loaded()
        if (!this.next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
    },
    updateQuery() {
      let query = filterUtil.mergeQueryDict(this.query, this.filter)
      this.$router.push({ query })
    },
    clearSearch() {
      this.filter.search = ''
      this.updateQuery()
    },
    pushDetail(row) {
      this.$router.push({
        name: 'user-detail',
        params: { id: row.item.uuid },
        query: this.$route.query,
      })
    },
  },
}
</script>

<style></style>
